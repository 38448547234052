











































import { Component, Vue } from 'vue-property-decorator'
import ContentLayout from '@/components/ContentLayout.vue'

@Component({
  components: { ContentLayout },
})
export default class Home extends Vue {
}
