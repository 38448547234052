import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
    },
    {
        path: '/articles',
        name: 'articles',
        component: () =>
            import(/* webpackChunkName: "articles" */ '../views/ArticlesView.vue'),
    },
    {
        path: '/about',
        name: 'about',
        component: () =>
            import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
    },
    {
        path: '/contact',
        name: 'contact',
        component: () =>
            import(/* webpackChunkName: "contact" */ '../views/ContactView.vue'),
    },
    { path: '*', redirect: '/' }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

export default router
