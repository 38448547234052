




































import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class App extends Vue {
  icons = [
    { icon: 'mdi-linkedin', link: 'https://www.linkedin.com/in/timdaviss/' },
    { icon: 'mdi-github', link: 'https://github.com/timdaviss' },
  ]
  navLinks = [
    { to: '/', text: '<span class="nav-main-tab">Tim Davis</span>' },
    { to: '/articles', text: 'Articles' },
    { to: '/about', text: 'About' },
    { to: '/contact', text: 'Contact' },
  ]
}
