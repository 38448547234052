import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#18C678',
                secondary: '#3c4a44',
                accent: '#18BDC6',
            }
        }
    }
})
